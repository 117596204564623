"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var product_1 = require("../../../Player/components/FeaturedProductsContainer/product");
var constants_1 = require("../../constants");
var constants_2 = require("./constants");
/**
 * Mounts Products Details element template
 * @param {Object} product data for product details.
 */
function default_1(product, isOutOfStock) {
    function renderQuantityOptions() {
        var options = lodash_1.times(constants_2.QUNANTITY_LIMIT, function (number) { return number + 1; });
        return lodash_1.join(lodash_1.map(options, function (number) {
            return "<option value=" + number + " >\n          " + number + "\n          </option>";
        }), '');
    }
    function renderForm() {
        if (!isOutOfStock) {
            return "<form name=\"" + constants_1.PRODUCT_DETAILS_FORM_NAME + "\">\n          <div>\n            <div class=\"" + constants_1.VARIANT_SELECTORS_CONTAINER + "\"></div>\n            <div class=\"product-form-input\">\n              <label class=\"variant-label\">Quantity</label>\n              <div class=\"select-wrapper\">\n                <select name=\"" + constants_2.QUNAITITY_SELECTOR_NAME + "\">\n                  " + renderQuantityOptions() + "\n                </select>\n              </div>\n            </div>\n            <p class=\"error-message\" hidden>Please select all the options</p>\n          </div>\n          <button type=\"submit\">Add to Bag</button>\n        </form>";
        }
        return "";
    }
    return "<div class=\"" + constants_2.PRODUCT_DETAILS_CLASS + "\" id=\"product-list\">\n      <button type=\"button\" class=\"close-button\">\n        <svg width=\"29\" height=\"29\" viewBox=\"0 0 29 29\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n          <path opacity=\"0.2\" d=\"M14.5 29C6.49165 29 0 22.5084 0 14.5C0 6.49165 6.49165 0 14.5 0C22.5084 \n            0 29 6.49165 29 14.5C29 22.5084 22.5084 29 14.5 29ZM14.5 12.4497L10.3994 8.34765L8.34765 \n            10.3994L12.4497 14.5L8.34765 18.6006L10.3994 20.6524L14.5 16.5503L18.6006 \n            20.6524L20.6524 18.6006L16.5503 14.5L20.6524 10.3994L18.6006 8.34765L14.5 12.4497Z\" \n            fill=\"#22174A\"/>\n        </svg>\n      </button>\n      " + product_1.default({ product: product, isOutOfStock: isOutOfStock }) + "\n      " + renderForm() + "\n    </div>";
}
exports.default = default_1;
