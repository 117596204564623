"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var getHeaders_1 = require("../utils/getHeaders");
/**
 * Intialises the data layer of the library
 */
var DataSource = /** @class */ (function () {
    function DataSource(liveInstance) {
        this.live = liveInstance;
    }
    /**
     * Handles the API request
     * @param {Object} opts options for the request
     */
    DataSource.prototype.request = function (opts) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, method, data, path, auth, apiUrl, response_1, fetchOptions, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headers = opts.headers, method = opts.method, data = opts.data, path = opts.path, auth = opts.auth;
                        apiUrl = "" + this.live.apiUrl + path;
                        if (!auth) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.live.auth.getToken()];
                    case 1:
                        response_1 = _a.sent();
                        headers['Authorization'] = "Bearer " + response_1.auth_token;
                        _a.label = 2;
                    case 2:
                        fetchOptions = __assign({ method: method,
                            headers: headers }, (!lodash_1.isEmpty(data) && {
                            body: JSON.stringify(data),
                        }));
                        return [4 /*yield*/, fetch(apiUrl, fetchOptions)
                                .catch(function (error) {
                                throw error;
                            })
                                .then(function (res) { return res.json(); })
                                .then(function (data) {
                                return data;
                            })];
                    case 3:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Builds the request handler
     * @param {Object} options options for the request
     */
    DataSource.prototype.requestBuilder = function (options) {
        var _this = this;
        if (options.requestHandler) {
            return options.requestHandler;
        }
        return function () {
            var opts = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                opts[_i] = arguments[_i];
            }
            var requestPath = options.path;
            if (typeof options.path == 'function') {
                requestPath = options.path.apply(options, opts);
            }
            return _this.request({
                data: options.data || {},
                method: options.method,
                path: requestPath,
                headers: getHeaders_1.default(_this.live.key),
                auth: options.authenticated || true,
            });
        };
    };
    /**
     * Creates an object of callable functions that retrieve data from
     * the API
     * @param {Array} endpoints List of endpoint configuration
     */
    DataSource.prototype.buildRepository = function (endpoints) {
        var _this = this;
        return Object.keys(endpoints).reduce(function (acc, endpointKey) {
            acc[endpointKey] = _this.requestBuilder(endpoints[endpointKey]);
            return acc;
        }, {});
    };
    return DataSource;
}());
exports.default = DataSource;
