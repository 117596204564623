"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var constants_1 = require("../Player/components/FeaturedProductsContainer/constants");
var product_1 = require("../Player/components/FeaturedProductsContainer/product");
var ProductDetails_1 = require("./components/ProductDetails");
var VariantRadio_1 = require("./components/VariantRadio");
var VariantSelect_1 = require("./components/VariantSelect");
var Shopify_1 = require("./Shopify");
var constants_2 = require("./components/ProductDetails/constants");
var constants_3 = require("./constants");
var FeaturedProductsContainer_1 = require("../Player/components/FeaturedProductsContainer");
var BagButton_1 = require("./components/BagButton");
var Bag_1 = require("./components/Bag");
var FeaturedProducts = /** @class */ (function () {
    function FeaturedProducts(_a) {
        var _this = this;
        var playerContainerElement = _a.playerContainerElement, featuredProductsContainerElement = _a.featuredProductsContainerElement, products = _a.products, shopifyStoreDetails = _a.shopifyStoreDetails;
        this.handleBagButtonClick = function () {
            _this.isBagOpen = !_this.isBagOpen;
            if (_this.isBagOpen) {
                _this.onBagOpen();
            }
            else {
                _this.onBagClose();
            }
        };
        this.onProductFormSubmit = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var orderDetailsForm, ErrorMesssageElement, formData, checkout;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        orderDetailsForm = (document.querySelector("form[name=\"" + constants_3.PRODUCT_DETAILS_FORM_NAME + "\"]"));
                        ErrorMesssageElement = (orderDetailsForm.querySelector('.error-message'));
                        event.preventDefault();
                        formData = new FormData(event.target);
                        this.selectedVariantOptions = {
                            quantity: lodash_1.parseInt(formData.get(constants_2.QUNAITITY_SELECTOR_NAME)),
                        };
                        if (!ErrorMesssageElement.hidden) {
                            ErrorMesssageElement.hidden = true;
                        }
                        lodash_1.forEach(this.variantDetails, function (_a) {
                            var _b;
                            var name = _a.name;
                            var value = formData.get("" + name + constants_3.VARIANT_SELECTOR_NAME_SUFFIX);
                            if (value) {
                                _this.selectedVariantOptions = __assign(__assign({}, _this.selectedVariantOptions), (_b = {}, _b[name] = value, _b));
                            }
                            else {
                                ErrorMesssageElement.hidden = false;
                            }
                        });
                        if (!ErrorMesssageElement.hidden) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.shopifyInstance.addProductToBag({
                                product: this.productDetails,
                                variantOptions: this.selectedVariantOptions,
                            })];
                    case 1:
                        checkout = _a.sent();
                        this.updateBagButtonElement(lodash_1.size(checkout.lineItems));
                        this.onCloseProductDetails();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        this.onCloseProductDetails = function () {
            if (!lodash_1.isNil(_this.textContainerElement)) {
                _this.textContainerElement.innerHTML = FeaturedProductsContainer_1.renderButtonText();
            }
            document.querySelector("." + constants_2.PRODUCT_DETAILS_CLASS).remove();
            document.getElementById(constants_1.PRODUCT_LIST_ELEMENT_ID).hidden = false;
        };
        this.playerContainerElement = playerContainerElement;
        this.featuredProductsContainerElement = featuredProductsContainerElement;
        this.shopifyStoreDetails = shopifyStoreDetails;
        this.textContainerElement = document.querySelector("." + constants_3.PRODUCTS_TOGGLE_TEXT_CLASS);
        this.isBagOpen = false;
        this.hasBagButtonRendered = false;
        this.products = [];
        this.updateProducts(products);
    }
    FeaturedProducts.prototype.updateFeaturedProductsContainerElement = function (featuredProductsContainerElement) {
        this.featuredProductsContainerElement = featuredProductsContainerElement;
        featuredProductsContainerElement.querySelector("#" + constants_1.PRODUCT_LIST_ELEMENT_ID).innerHTML = '';
        this.products = [];
    };
    FeaturedProducts.prototype.changeContainerHiddenState = function (productList) {
        if (lodash_1.isEmpty(productList) && !this.featuredProductsContainerElement.hidden) {
            this.featuredProductsContainerElement.hidden = true;
        }
        else if (!lodash_1.isEmpty(productList) &&
            this.featuredProductsContainerElement.hidden) {
            this.featuredProductsContainerElement.hidden = false;
        }
    };
    FeaturedProducts.prototype.updateProducts = function (featuredProductsUpdate) {
        var _this = this;
        this.changeContainerHiddenState(featuredProductsUpdate);
        var unfeaturedProducts = lodash_1.differenceBy(this.products, featuredProductsUpdate, 'product.id');
        var newFeaturedProducts = lodash_1.differenceBy(featuredProductsUpdate, this.products, 'product.id');
        lodash_1.forEach(unfeaturedProducts, function (_a) {
            var unfeaturedProduct = _a.product;
            _this.removeProduct(unfeaturedProduct);
        });
        if (!lodash_1.isEmpty(newFeaturedProducts)) {
            this.renderProducts(newFeaturedProducts);
        }
        this.products = featuredProductsUpdate;
    };
    FeaturedProducts.prototype.renderProducts = function (productList) {
        var _this = this;
        lodash_1.forEach(productList, function (_a) {
            var product = _a.product;
            _this.renderProduct(product);
        });
    };
    FeaturedProducts.prototype.renderProduct = function (product) {
        if (lodash_1.isEqual(product.product_type, 'shopify') &&
            !lodash_1.isEmpty(this.shopifyStoreDetails) &&
            !lodash_1.isEmpty(this.shopifyStoreDetails.storefrontAccessToken)) {
            this.renderShopifyProduct(product);
        }
        else {
            this.renderLiveProduct(product);
        }
    };
    FeaturedProducts.prototype.renderLiveProduct = function (product) {
        document
            .getElementById(constants_1.PRODUCT_LIST_ELEMENT_ID)
            .insertAdjacentHTML('afterbegin', product_1.default({ product: product, useUrl: true }));
    };
    FeaturedProducts.prototype.renderBag = function (checkout) {
        return __awaiter(this, void 0, void 0, function () {
            var fetchedCheckout, _a, BAG_ELEMENT;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = checkout;
                        if (_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.shopifyInstance.getCheckout()];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2:
                        fetchedCheckout = _a;
                        BAG_ELEMENT = this.featuredProductsContainerElement.querySelector("." + constants_3.SHOPIFY_BAG_CLASS);
                        if (BAG_ELEMENT) {
                            BAG_ELEMENT.remove();
                        }
                        this.featuredProductsContainerElement.insertAdjacentHTML('beforeend', Bag_1.default(fetchedCheckout));
                        this.featuredProductsContainerElement.classList.add(constants_3.BAG_OPEN_CLASS);
                        this.featuredProductsContainerElement
                            .querySelector("." + constants_3.SHOPIFY_BAG_CLASS + " ." + constants_3.SHOPIFY_BAG_BACK_BUTTON_CLASS)
                            .addEventListener('click', this.handleBagButtonClick);
                        lodash_1.forEach(fetchedCheckout.lineItems, function (_a) {
                            var id = _a.id;
                            document
                                .getElementById(id)
                                .querySelector("." + constants_3.REMOVE_ITEM_BUTTON_CLASS)
                                .addEventListener('click', function () {
                                _this.onRemoveLineItem(id);
                            });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    FeaturedProducts.prototype.onRemoveLineItem = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var checkout;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.shopifyInstance.deleteCheckoutLineItem(id)];
                    case 1:
                        checkout = _a.sent();
                        return [4 /*yield*/, this.renderBag(checkout)];
                    case 2:
                        _a.sent();
                        this.updateBagButtonElement(lodash_1.size(checkout.lineItems));
                        return [2 /*return*/];
                }
            });
        });
    };
    FeaturedProducts.prototype.onBagOpen = function () {
        return __awaiter(this, void 0, void 0, function () {
            var ProductDetailsElement;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ProductDetailsElement = this.featuredProductsContainerElement.querySelector("." + constants_2.PRODUCT_DETAILS_CLASS);
                        if (lodash_1.isEmpty(this.shopifyInstance)) {
                            this.shopifyInstance = new Shopify_1.default(this.shopifyStoreDetails);
                        }
                        return [4 /*yield*/, this.renderBag()];
                    case 1:
                        _a.sent();
                        if (!lodash_1.isNil(this.textContainerElement)) {
                            this.textContainerElement.innerHTML = FeaturedProductsContainer_1.renderButtonText('Shopping Cart');
                        }
                        document.getElementById(constants_1.PRODUCT_LIST_ELEMENT_ID).hidden = true;
                        if (!lodash_1.isNil(ProductDetailsElement)) {
                            ProductDetailsElement.remove();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    FeaturedProducts.prototype.onBagClose = function () {
        this.featuredProductsContainerElement
            .querySelector("." + constants_3.SHOPIFY_BAG_CLASS)
            .remove();
        this.featuredProductsContainerElement.classList.remove(constants_3.BAG_OPEN_CLASS);
        if (!lodash_1.isNil(this.textContainerElement)) {
            this.textContainerElement.innerHTML = FeaturedProductsContainer_1.renderButtonText();
        }
        document.getElementById(constants_1.PRODUCT_LIST_ELEMENT_ID).hidden = false;
    };
    FeaturedProducts.prototype.renderBagButton = function () {
        var LastControlElement = this.playerContainerElement.querySelector('.vjs-control-bar > :last-child');
        if (!lodash_1.isNil(LastControlElement)) {
            LastControlElement.insertAdjacentHTML('beforebegin', BagButton_1.default());
            this.playerContainerElement
                .querySelector("." + constants_3.SHOPIFY_BAG_BUTTON_CLASS)
                .addEventListener('click', this.handleBagButtonClick);
            this.hasBagButtonRendered = true;
        }
    };
    FeaturedProducts.prototype.renderShopifyProduct = function (product) {
        var _this = this;
        if (!this.hasBagButtonRendered) {
            this.renderBagButton();
        }
        document
            .getElementById(constants_1.PRODUCT_LIST_ELEMENT_ID)
            .insertAdjacentHTML('afterbegin', product_1.default({ product: product }));
        document
            .getElementById("product-" + product.id)
            .addEventListener('click', function () {
            _this.openShopifyProductDetails(product);
        });
    };
    FeaturedProducts.prototype.removeProduct = function (unfeaturedProduct) {
        var productElement = document.getElementById("product-" + unfeaturedProduct.id);
        if (!lodash_1.isNil(productElement)) {
            productElement.remove();
        }
    };
    FeaturedProducts.prototype.updateBagButtonElement = function (itemsInBag) {
        var CurrentBagButtonElement = this.playerContainerElement.querySelector("." + constants_3.SHOPIFY_BAG_BUTTON_CLASS);
        if (CurrentBagButtonElement) {
            CurrentBagButtonElement.remove();
        }
        this.playerContainerElement
            .querySelector('.vjs-control-bar > :last-child')
            .insertAdjacentHTML('beforebegin', BagButton_1.default(itemsInBag));
        this.playerContainerElement
            .querySelector("." + constants_3.SHOPIFY_BAG_BUTTON_CLASS)
            .addEventListener('click', this.handleBagButtonClick);
    };
    FeaturedProducts.prototype.openShopifyProductDetails = function (product) {
        return __awaiter(this, void 0, void 0, function () {
            var externalId, title, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        externalId = product.external_id, title = product.title;
                        if (lodash_1.isEmpty(this.shopifyInstance)) {
                            this.shopifyInstance = new Shopify_1.default(this.shopifyStoreDetails);
                        }
                        _a = this;
                        return [4 /*yield*/, this.shopifyInstance.getProduct(externalId)];
                    case 1:
                        _a.productDetails = _b.sent();
                        if (!lodash_1.isNil(this.textContainerElement)) {
                            this.textContainerElement.innerHTML = FeaturedProductsContainer_1.renderButtonText(title);
                        }
                        document.getElementById(constants_1.PRODUCT_LIST_ELEMENT_ID).hidden = true;
                        this.featuredProductsContainerElement.insertAdjacentHTML('beforeend', ProductDetails_1.default(product, !this.productDetails.availableForSale));
                        document
                            .querySelector("." + constants_2.PRODUCT_DETAILS_CLASS + " .close-button")
                            .addEventListener('click', this.onCloseProductDetails);
                        if (this.productDetails.availableForSale) {
                            this.renderShopifyVairantSelectors(this.productDetails);
                            document
                                .querySelector("form[name=\"" + constants_3.PRODUCT_DETAILS_FORM_NAME + "\"]")
                                .addEventListener('submit', this.onProductFormSubmit);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    FeaturedProducts.prototype.renderShopifyVairantSelectors = function (productDetails) {
        var _this = this;
        var options = productDetails.options;
        var variants = lodash_1.filter(options, function (_a) {
            var name = _a.name, values = _a.values;
            var isDefaultName = lodash_1.isEqual(name, 'Title');
            var isDefaultValue = lodash_1.isEqual(values[0].value, 'Default Title');
            return !(isDefaultName && isDefaultValue);
        });
        this.variantDetails = variants;
        lodash_1.forEach(variants, function (variant) {
            _this.renderShopifyVariant(variant);
        });
    };
    FeaturedProducts.prototype.isVariantAvailable = function (name, value) {
        for (var i = 0; i < this.productDetails.variants.length; i += 1) {
            var _a = this.productDetails.variants[i], selectedOptions = _a.selectedOptions, available = _a.available;
            if (available) {
                var isVariantPresent = lodash_1.find(selectedOptions, { name: name, value: value });
                if (isVariantPresent) {
                    return true;
                }
            }
        }
        return false;
    };
    FeaturedProducts.prototype.renderShopifyVariant = function (_a) {
        var _this = this;
        var name = _a.name, values = _a.values;
        var updatedVariantValues = lodash_1.map(values, function (_a) {
            var value = _a.value;
            return ({
                value: value,
                isOrderable: _this.isVariantAvailable(name, value),
            });
        });
        var RenderElement = lodash_1.isEqual(name.toLowerCase(), 'color')
            ? VariantSelect_1.default
            : VariantRadio_1.default;
        document
            .querySelector("." + constants_3.VARIANT_SELECTORS_CONTAINER)
            .insertAdjacentHTML('beforeend', RenderElement(name, updatedVariantValues));
    };
    return FeaturedProducts;
}());
exports.default = FeaturedProducts;
