"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
// TODO: extend to work on multi levels of the object
function convertObjectToSnakeCase(object) {
    return lodash_1.reduce(object, function (updatedObject, value, key) {
        var _a;
        return (__assign(__assign({}, updatedObject), (_a = {}, _a[lodash_1.snakeCase(key)] = value, _a)));
    }, {});
}
exports.default = convertObjectToSnakeCase;
