"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var query_string_1 = require("query-string");
var constants_1 = require("./constants");
var config_1 = require("../../config");
var convertObjectToSnakeCase_1 = require("../../utils/convertObjectToSnakeCase");
/**
 * Live Stream Player
 * @param {Object} liveInstance Live instance
 */
var EmbedPlayer = /** @class */ (function () {
    function EmbedPlayer(liveInstance, options) {
        this.iframe = null;
        this.playerOrigin = null;
        this.live = liveInstance;
        this.options = options || {};
    }
    /**
     * Mounts Live Player
     */
    EmbedPlayer.prototype.mount = function (element, options) {
        var target = element;
        if (lodash_1.isNull(target)) {
            throw new Error('No match found for selector ' + element);
        }
        this.iframe =
            target.tagName !== 'IFRAME'
                ? this.createIframe(target)
                : target;
        var iframeDomainOrigin = !lodash_1.isNil(config_1.default.iframeDomain)
            ? config_1.default.iframeDomain
            : constants_1.DEFAULT_IFRAME_DOMAIN;
        this.iframeUrl =
            this.options.iframeUrl ||
                iframeDomainOrigin + "?api_key=" + this.live.key + "&environment=" + this.live.options.environment + "&" + this.getPlayerConfigParams(options);
        this.playerOrigin = new URL(this.iframeUrl).origin;
        if (!this.iframe.src) {
            this.createNewPlayer(this.iframe, this.options);
        }
        // @Todo: do something when a player already exists
    };
    /**
     * Starts Video
     */
    EmbedPlayer.prototype.start = function () {
        this.postMessage({ message: 'start' });
    };
    /**
     * Stops Video
     */
    EmbedPlayer.prototype.stop = function () {
        this.postMessage({ message: 'stop' });
    };
    /**
     * Enters fullscreen for player
     */
    EmbedPlayer.prototype.openFullscreen = function () {
        this.postMessage({ message: 'openFullscreen' });
    };
    /**
     * Exits fullscreen for player
     */
    EmbedPlayer.prototype.closeFullscreen = function () {
        this.postMessage({ message: 'closeFullscreen' });
    };
    /**
     * Destroys Live Player
     */
    EmbedPlayer.prototype.dispose = function () {
        this.postMessage({ message: 'dispose' });
    };
    /**
     * Changes volume on player
     */
    EmbedPlayer.prototype.volume = function (volume) {
        this.postMessage({ message: 'volume', args: volume });
    };
    EmbedPlayer.prototype.getPlayerConfigParams = function (options) {
        var _a = options || {}, channelId = _a.channelId, playerOptions = _a.options;
        var params = __assign({ show_id: channelId }, convertObjectToSnakeCase_1.default(playerOptions));
        return query_string_1.stringify(params);
    };
    EmbedPlayer.prototype.createIframe = function (target) {
        var ifr = document.createElement('iframe');
        ifr.style.height = '100%';
        ifr.style.width = '100%';
        ifr.style.border = 'none';
        ifr.allowFullscreen = true;
        target.appendChild(ifr);
        return ifr;
    };
    EmbedPlayer.prototype.createNewPlayer = function (iframe, options) {
        var iframeUrl = this.iframeUrl;
        this.setIframeSrc(iframe, iframeUrl);
    };
    EmbedPlayer.prototype.setIframeSrc = function (iframe, url) {
        iframe.src = url;
    };
    EmbedPlayer.prototype.postMessage = function (message) {
        var _a;
        if (!this.playerOrigin || !((_a = this.iframe) === null || _a === void 0 ? void 0 : _a.contentWindow)) {
            return;
        }
        if (this.playerOrigin) {
            this.iframe.contentWindow.postMessage(message, this.playerOrigin);
        }
    };
    return EmbedPlayer;
}());
exports.default = EmbedPlayer;
