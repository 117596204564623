"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var constants_1 = require("../../constants");
/**
 * Mounts Product Variant selector template
 * @param name name of the variant option
 * @param values values of the variant option
 */
function default_1(name, values) {
    function renderOptions() {
        return lodash_1.join(lodash_1.map(values, function (_a) {
            var value = _a.value, isOrderable = _a.isOrderable;
            return "<option value=\"" + value + "\" " + (!isOrderable && 'disabled') + ">\n          " + value + "\n          </option>";
        }), '');
    }
    return "<div class=\"product-form-input\">\n    <label class=\"variant-label\" for=\"" + name + constants_1.VARIANT_SELECTOR_NAME_SUFFIX + "\">" + name + "</label>\n    <div class=\"select-wrapper\">\n      <select id=\"" + name + constants_1.VARIANT_SELECTOR_NAME_SUFFIX + "\" name=\"" + name + constants_1.VARIANT_SELECTOR_NAME_SUFFIX + "\">\n        " + renderOptions() + "\n      </select>\n    </div>\n  </div>";
}
exports.default = default_1;
