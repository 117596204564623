"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRE_FETCH_VALUE = exports.VOD_PLAYER_OPTIONS = void 0;
exports.VOD_PLAYER_OPTIONS = {
    autoplay: false,
    muted: false,
    controls: true,
    nativeControlsForTouch: true,
    controlBar: {
        volumePanel: true,
        muteToggle: false,
        pictureInPictureToggle: false,
        remainingTimeDisplay: false,
    },
};
exports.PRE_FETCH_VALUE = 0.5;
