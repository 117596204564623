"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var query_string_1 = require("query-string");
var elements_1 = require("./elements");
var dataSource_1 = require("./dataSource");
var auth_1 = require("./auth");
var sockets_1 = require("./sockets");
require("./styles/index.css");
var getApiUrl_1 = require("../utils/getApiUrl");
var getSocketUrl_1 = require("../utils/getSocketUrl");
var Live = /** @class */ (function () {
    function Live(liveKey, givenOpts) {
        if (givenOpts === void 0) { givenOpts = {}; }
        this.key = liveKey;
        this.options = givenOpts;
        this.elements = null;
        this.episode = null;
        this.apiUrl = getApiUrl_1.default(givenOpts.environment);
        this.socketUrl = getSocketUrl_1.default(givenOpts.environment);
        this.init();
    }
    /**
     * Intialises library using key
     */
    Live.prototype.init = function () {
        this.auth = new auth_1.default(this);
        this.dataSource = new dataSource_1.default(this);
        this.sockets = new sockets_1.default(this);
        this.episodes = this.dataSource.buildRepository({
            list: {
                path: function (_a) {
                    var params = _a.params;
                    return "v1/episodes" + (lodash_1.isEmpty(params) ? '' : "?" + query_string_1.stringify(params));
                },
                method: 'GET',
            },
            watch: {
                path: function (_a) {
                    var episode = _a.episode;
                    return "v1/episodes/" + episode + "/watch";
                },
                method: 'GET',
            },
            liveStream: {
                path: function (_a) {
                    var episode = _a.episode;
                    return "live-streams/" + episode;
                },
                method: 'GET',
            },
            activeShow: {
                path: function (_a) {
                    var showId = _a.showId;
                    return "episodes/current?channel_id=" + showId;
                },
                method: 'GET',
            },
            showPreview: {
                path: function (_a) {
                    var showId = _a.showId;
                    return "channels/" + showId + "/public";
                },
                method: 'GET',
            },
            activeStreams: {
                path: "episodes/current",
                method: 'GET',
            },
            featuredProducts: {
                path: function (_a) {
                    var episodeId = _a.episodeId, params = _a.params;
                    return "v1/shopping/episodes/" + episodeId + "/featured-products" + (lodash_1.isEmpty(params) ? '' : "?" + query_string_1.stringify(params));
                },
                method: 'GET',
            },
            highlightedFeaturedProducts: {
                path: function (_a) {
                    var episodeId = _a.episodeId, params = _a.params;
                    return "v1/shopping/episodes/" + episodeId + "/highlighted-featured-products" + (lodash_1.isEmpty(params) ? '' : "?" + query_string_1.stringify(params));
                },
                method: 'GET',
            },
            next: {
                path: 'v1/episodes/next',
                method: 'GET',
            },
        });
        this.vod = this.dataSource.buildRepository({
            items: {
                path: function (_a) {
                    var params = _a.params;
                    return "vod/items" + (lodash_1.isEmpty(params) ? '' : "?" + new URLSearchParams(params).toString());
                },
                method: 'GET',
            },
            videos: {
                path: function (_a) {
                    var params = _a.params;
                    return "vod/videos" + (lodash_1.isEmpty(params) ? '' : "?" + new URLSearchParams(params).toString());
                },
                method: 'GET',
            },
            video: {
                path: function (videoId) { return "vod/videos/" + videoId; },
                method: 'GET',
            },
            videoFeaturedProductsMeta: {
                path: function (_a) {
                    var params = _a.params, videoId = _a.videoId;
                    return "v1/shopping/videos/" + videoId + "/featured-products/meta" + (lodash_1.isEmpty(params) ? '' : "?" + new URLSearchParams(params).toString());
                },
                method: 'GET',
            },
            videoFeaturedProducts: {
                path: function (_a) {
                    var params = _a.params, videoId = _a.videoId;
                    return "v1/shopping/videos/" + videoId + "/featured-products" + (lodash_1.isEmpty(params) ? '' : "?" + new URLSearchParams(params).toString());
                },
                method: 'GET',
            },
            categories: {
                path: function (_a) {
                    var params = _a.params;
                    return "vod/categories" + (lodash_1.isEmpty(params) ? '' : "?" + new URLSearchParams(params).toString());
                },
                method: 'GET',
            },
            category: {
                path: function (categoryId) { return "vod/categories/" + categoryId; },
                method: 'GET',
            },
            playlistInfo: {
                path: function (playlistId) { return "vod/playlists/" + playlistId; },
                method: 'GET',
            },
        });
        this.shoppingCatalogue = this.dataSource.buildRepository({
            productDetails: {
                path: function (productId) {
                    return "dashboard/shopping/catalogue/products/" + productId;
                },
                method: 'GET',
            },
            shopifyConfig: {
                path: 'integrations/shopify/storefront-access-token',
                method: 'GET',
            },
        });
        this.elements = new elements_1.default(this);
    };
    return Live;
}());
exports.default = Live;
