"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var constants_1 = require(".././Live/constants");
var config_1 = require("../config");
var production = constants_1.environmentMapping.production;
var getSocketUrl = function (environment) {
    // defaults to production
    var backendDomain = lodash_1.isEmpty(constants_1.environmentMapping[environment])
        ? production
        : constants_1.environmentMapping[environment];
    return "" + config_1.socketPrefix + backendDomain;
};
exports.default = getSocketUrl;
