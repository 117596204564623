"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BAG_OPEN_CLASS = exports.SHOPIFY_BAG_BACK_BUTTON_CLASS = exports.REMOVE_ITEM_BUTTON_CLASS = exports.SHOPIFY_BAG_BUTTON_CLASS = exports.SHOPIFY_BAG_CLASS = exports.VARIANT_SELECTOR_NAME_SUFFIX = exports.VARIANT_SELECTORS_CONTAINER = exports.PRODUCT_DETAILS_FORM_NAME = exports.PRODUCTS_TOGGLE_TEXT_CLASS = void 0;
exports.PRODUCTS_TOGGLE_TEXT_CLASS = 'button-text-container';
exports.PRODUCT_DETAILS_FORM_NAME = 'shopify-product-form';
exports.VARIANT_SELECTORS_CONTAINER = 'variant-selectors-container';
exports.VARIANT_SELECTOR_NAME_SUFFIX = '-variant-selector';
exports.SHOPIFY_BAG_CLASS = 'shopify-bag';
exports.SHOPIFY_BAG_BUTTON_CLASS = 'shopify-bag-button';
exports.REMOVE_ITEM_BUTTON_CLASS = 'remove-item-button';
exports.SHOPIFY_BAG_BACK_BUTTON_CLASS = 'back-button';
exports.BAG_OPEN_CLASS = 'bag-open';
