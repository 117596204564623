"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var constants_1 = require("../../constants");
/**
 * Mounts Shopify Bag template
 * @param items items in the bag
 */
function default_1(checkout) {
    var lineItems = checkout.lineItems, amount = checkout.lineItemsSubtotalPrice.amount, webUrl = checkout.webUrl;
    function renderItems() {
        return lodash_1.join(lodash_1.map(lineItems, function (item) {
            var lineItemId = item.id, quantity = item.quantity, title = item.title, _a = item.variant, src = _a.image.src, price = _a.price;
            return "<div id=\"" + lineItemId + "\" class=\"shopify-item-container\">\n          <div class=\"item-details\">\n            <div class=\"product-details\">\n              <span class=\"title\">" + title + "</span>\n              <span class=\"price\">$" + price + "</span>\n            </div>\n            <span class=\"qunatity\">Quantity " + quantity + "</span>\n          </div>\n          <img class=\"product-image\" src=" + src + " alt=" + title + " />\n          <button type=\"button\" class=\"remove-item-button\">\n            <svg viewBox=\"0 0 14 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n              <path d=\"M1.289 1.289 12.71 12.71M12.711 1.289 1.289 12.71\" stroke=\"currentColor\"/>\n            </svg>\n          </button>\n        </div>";
        }), '');
    }
    return "\n    <div class=\"" + constants_1.SHOPIFY_BAG_CLASS + "\">\n      <div>\n        <button class=\"" + constants_1.SHOPIFY_BAG_BACK_BUTTON_CLASS + "\">\n          <svg width=\"25\" height=\"25\" viewBox=\"0 0 25 25\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n            <path d=\"M15.625 18.75L9.375 12.5L15.625 6.25\" stroke=\"#22174A\" stroke-width=\"2\" stroke-linejoin=\"round\"/>\n          </svg>\n          " + lodash_1.size(lineItems) + " Items\n        </button>\n        " + renderItems() + "\n        <div class=\"bag-total\">\n          <span>Estimated Total</span>\n          <span>$" + amount + "</span>\n        </div>\n      </div>\n      <a href=\"" + webUrl + "\" target=\"_blank\" rel=\"noopener noreferrer\">Go to Checkout</a>\n    </div>\n    ";
}
exports.default = default_1;
