"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var constants_1 = require("../../constants");
/**
 * Mounts Product Variant Radio selector template
 * @param name name of the variant option
 * @param values values of the variant option
 */
function default_1(name, values) {
    function renderOptions() {
        return lodash_1.join(lodash_1.map(values, function (_a) {
            var value = _a.value, isOrderable = _a.isOrderable;
            return "<span>\n            <input id=\"" + name + constants_1.VARIANT_SELECTOR_NAME_SUFFIX + value + "\" \n              type=\"radio\" name=\"" + name + constants_1.VARIANT_SELECTOR_NAME_SUFFIX + "\" \n              value=\"" + value + "\" " + (!isOrderable && 'disabled') + "/>\n            <label for=\"" + name + constants_1.VARIANT_SELECTOR_NAME_SUFFIX + value + "\">\n              " + value + "\n            </label>\n          </span>";
        }), '');
    }
    return "<div class=\"product-form-input\">\n    <label class=\"variant-label\">" + name + "</label>\n    <div class=\"radio-options-container\">\n      " + renderOptions() + "\n    <div>\n  </div>\n  ";
}
exports.default = default_1;
