"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var video_js_1 = require("video.js");
var lodash_1 = require("lodash");
var playerElement_1 = require("./playerElement");
var loaderElement_1 = require("./loaderElement");
var streamEndedElement_1 = require("./streamEndedElement");
var streamEmptyStateElement_1 = require("./streamEmptyStateElement");
var streamPreviewElement_1 = require("./streamPreviewElement");
var errorElement_1 = require("./errorElement");
var constants_1 = require("./constants");
var constants_2 = require("../constants");
var constants_3 = require("./components/FeaturedProductsContainer/constants");
var OverlayContent_1 = require("./components/OverlayContent");
var duration_1 = require("../../utils/duration");
var FeaturedProducts_1 = require("../FeaturedProducts");
/**
 * Live Stream Player
 * @param {Object} liveInstance Live instance
 */
var LivePlayer = /** @class */ (function () {
    function LivePlayer(liveInstance, options) {
        this.live = liveInstance;
        this.containerElement = null;
        this.playerElement = null;
        this.element = null;
        this.player = null;
        this.state = this.initialState(options);
        this.options = options || {};
    }
    /**
     * Mounts Live Player
     */
    LivePlayer.prototype.mount = function (element, _a) {
        var showId = _a.showId, livePlayerConfig = _a.livePlayerConfig;
        this.livePlayerConfig = livePlayerConfig;
        if (!lodash_1.isUndefined(element)) {
            this.containerElement = element;
        }
        if (!lodash_1.isEmpty(showId)) {
            this.loadShow(showId);
            this.subscribeShowSocketEvents(showId);
        }
        else {
            this.loadActiveStreams();
            this.subscribeSocketEvents();
        }
    };
    LivePlayer.prototype.loadShow = function (showId) {
        var _this = this;
        if (!this.state.episode) {
            this.state.loading = true;
            this.updatePlayer();
        }
        this.live.elements
            .currentShow(showId)
            .then(function (data) {
            var count = data.count, results = data.results;
            if (count === 0) {
                _this.live.elements
                    .currentShowPreview(showId)
                    .then(function (data) {
                    var _a = _this.livePlayerConfig, hideTitle = _a.hideCoverTitle, hideDescription = _a.hideCoverDescription;
                    _this.state.episode = data;
                    _this.containerElement.innerHTML = streamPreviewElement_1.default(data, {
                        hideTitle: hideTitle,
                        hideDescription: hideDescription,
                    });
                })
                    .catch(function (err) {
                    console.log('err', err);
                });
            }
            else {
                if (count >= 1) {
                    var _a = results[0], streamStatus = _a.stream_status, preRecordedVideo = _a.pre_recorded_video;
                    _this.state.loading = false;
                    // initiate broadcast only if the stream status is active or vod is attached.
                    if (preRecordedVideo ||
                        streamStatus === constants_2.episodeStreamStatus.ACTIVE) {
                        _this.state.episode = results[0];
                        // Don't run updatePlayer() but start initiateBroadcast instead and wait until loaded.
                        _this.initiateBroadcast();
                    }
                    else {
                        _this.containerElement.innerHTML = streamEmptyStateElement_1.default();
                    }
                }
                else {
                    _this.state.loading = false;
                    _this.updatePlayer();
                }
            }
        })
            .catch(function (err) {
            console.log('Error', err);
            var error = JSON.stringify(err);
            _this.containerElement.innerHTML = errorElement_1.default(error);
        });
    };
    /**
     * Initiates the player to load the broadcast information, done when
     * the stream is "broadcasting"
     */
    LivePlayer.prototype.initiateBroadcast = function () {
        var _this = this;
        if (!lodash_1.isEmpty(this.state.episode)) {
            var id = this.state.episode.id;
            this.watchPromise = this.live.episodes.watch({ episode: id });
            this.watchPromise.then(function (data) {
                _this.state.broadcast = data;
                _this.updatePlayer();
            });
            // featch featured products for the episode.
            this.live.elements
                .featuredProducts(id)
                .then(function (_a) {
                var results = _a.results;
                _this.state.featuredProducts = results;
                _this.updatePlayer();
            })
                .catch(function (err) {
                console.log('err', err);
            });
            // Join Featuered Prodcuts Socket Updates
            if (!this.livePlayerConfig.hideShoppingCart) {
                this.joinFeaturedProductsUpdates(id);
            }
        }
    };
    LivePlayer.prototype.subscribeShowSocketEvents = function (showId) {
        var _this = this;
        this.live.sockets.subscribeToEvent(constants_2.socketCommandTypes.EPISODE_STATUS_UPDATE, function (message) {
            var streamStatus = message.stream_status, episode = message.episode, playbackCutoff = message.extra.playback_cutoff;
            if (!lodash_1.isNull(episode)) {
                if (episode.channel_id === showId) {
                    if (episode.status === constants_2.episodeStatus.BROADCASTING) {
                        _this.loadShow(showId);
                    }
                }
                // playbackCutoff is a bool;
                // It is true when we close the stream from OBS
                // If it is true, we play the whole stream
                if (!playbackCutoff && episode.status === constants_2.episodeStatus.FINISHED) {
                    _this.loadShow(showId);
                }
            }
        });
    };
    LivePlayer.prototype.loadActiveStreams = function () {
        var _this = this;
        if (!this.state.episode) {
            this.state.loading = true;
            this.updatePlayer();
        }
        this.live.elements
            .activeStreams()
            .then(function (data) {
            var count = data.count, results = data.results;
            if (count >= 1) {
                var _a = results[0], streamStatus = _a.stream_status, preRecordedVideo = _a.pre_recorded_video;
                _this.state.loading = false;
                // initiate broadcast only if the stream status is active or there is a vod attached.
                if (preRecordedVideo || streamStatus === constants_2.episodeStreamStatus.ACTIVE) {
                    _this.state.episode = results[0];
                    // Don't run updatePlayer() but start initiateBroadcast instead and wait until loaded.
                    _this.initiateBroadcast();
                }
                else {
                    _this.containerElement.innerHTML = streamEmptyStateElement_1.default();
                }
            }
            else {
                _this.state.loading = false;
                _this.updatePlayer();
            }
        })
            .catch(function (err) {
            console.log('Error', err);
            var error = JSON.stringify(err);
            _this.containerElement.innerHTML = errorElement_1.default(error);
        });
    };
    LivePlayer.prototype.subscribeSocketEvents = function () {
        var _this = this;
        this.live.sockets.subscribeToEvent(constants_2.socketCommandTypes.EPISODE_STATUS_UPDATE, function (message) {
            var streamStatus = message.stream_status, episode = message.episode, playbackCutoff = message.extra.playback_cutoff;
            // playbackCutoff is a bool;
            // It is true when we close the stream from OBS
            // If it is true, we play the whole stream
            if (!lodash_1.isNull(_this.state.episode) &&
                _this.state.episode.id === episode.id &&
                !playbackCutoff &&
                episode.status === constants_2.episodeStatus.FINISHED) {
                _this.endScreen(_this.containerElement);
            }
            else if (lodash_1.isNull(_this.state.episode) &&
                streamStatus === constants_2.episodeStreamStatus.ACTIVE) {
                _this.loadActiveStreams();
            }
            else if (episode.status === constants_2.episodeStatus.BROADCASTING) {
                _this.state.episode = episode;
                _this.loadActiveStreams();
            }
        });
    };
    LivePlayer.prototype.updateFeaturedProductsContainer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, domain, storefrontAccessToken, featuredProductsContainerElement, featuredProductsContainerElement;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.state.loadingShopifyConfig) return [3 /*break*/, 3];
                        if (!lodash_1.isEmpty(this.featuredProductsInstance)) return [3 /*break*/, 2];
                        this.state.loadingShopifyConfig = true;
                        return [4 /*yield*/, this.live.elements.getShopifyConfig()];
                    case 1:
                        _a = (_b.sent()) || {}, domain = _a.shop_url, storefrontAccessToken = _a.access_token;
                        this.state.loadingShopifyConfig = false;
                        featuredProductsContainerElement = document.getElementById(constants_3.FEATURED_PRODUCTS_CONTAINER_ELEMENT_ID);
                        if (!lodash_1.isNil(featuredProductsContainerElement)) {
                            this.featuredProductsInstance = new FeaturedProducts_1.default({
                                playerContainerElement: this.containerElement,
                                featuredProductsContainerElement: featuredProductsContainerElement,
                                products: this.state.featuredProducts,
                                shopifyStoreDetails: {
                                    domain: lodash_1.replace(domain, 'https://', ''),
                                    storefrontAccessToken: storefrontAccessToken,
                                },
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        featuredProductsContainerElement = document.getElementById(constants_3.FEATURED_PRODUCTS_CONTAINER_ELEMENT_ID);
                        if (!lodash_1.isNil(featuredProductsContainerElement)) {
                            this.featuredProductsInstance.updateFeaturedProductsContainerElement(featuredProductsContainerElement);
                            this.featuredProductsInstance.updateProducts(this.state.featuredProducts);
                        }
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Joins 'join-episode-featured-product-updates' socket event
     * and subscribes to 'episode-featured-product-updates' event.
     */
    LivePlayer.prototype.joinFeaturedProductsUpdates = function (episodeId) {
        var _this = this;
        var socketMessage = JSON.stringify({
            command: constants_2.socketCommandTypes.JOIN_EPISODE_FEATURED_PRODUCT_UPDATES,
            episode_id: episodeId,
        });
        this.live.sockets.send(socketMessage);
        this.live.sockets.subscribeToEvent(constants_2.socketCommandTypes.EPISODE_FEATURED_PRODUCT_UPDATES, function (message) {
            var featuredProductsUpdate = message.highlighted_featured_products;
            _this.state.featuredProducts = featuredProductsUpdate;
            _this.updateFeaturedProductsContainer();
        });
    };
    LivePlayer.prototype.updatePlayer = function () {
        if (this.state.loading) {
            this.containerElement.innerHTML = loaderElement_1.default();
        }
        else if (this.state.episode && this.state.broadcast) {
            this.containerElement.innerHTML = playerElement_1.default(this.state.episode);
            this.loadPlayer();
        }
        else {
            this.containerElement.innerHTML = streamEmptyStateElement_1.default();
        }
    };
    /**
     * Loads Video JS Player
     */
    LivePlayer.prototype.loadPlayer = function () {
        this.playerElement = document.querySelector(constants_1.VIDEOJS_SELECTOR);
        video_js_1.default(this.playerElement, constants_1.PLAYER_OPTIONS);
        if (!lodash_1.isEmpty(this.state.episode)) {
            this.loadVideo();
        }
    };
    /**
     * Loads Video URL
     */
    LivePlayer.prototype.loadVideo = function () {
        var _this = this;
        var _a = this.state.broadcast, broadcastUrl = _a.broadcast_url, elapsedTime = _a.elapsed_time, streamType = _a.stream_type;
        var player = video_js_1.default.getPlayer(this.playerElement);
        player
            .el()
            .insertAdjacentHTML('afterBegin', OverlayContent_1.default(this.state.episode, this.livePlayerConfig));
        if (!lodash_1.isEmpty(this.state.featuredProducts) &&
            !this.livePlayerConfig.hideShoppingCart) {
            this.updateFeaturedProductsContainer();
        }
        this.player = player;
        player.src({
            src: broadcastUrl,
            withCredentials: false,
        });
        player.play();
        if (!lodash_1.isEmpty(elapsedTime) &&
            lodash_1.isEqual(streamType, constants_1.STREAM_TYPES.PRE_RECORDED_LIVE_STREAM)) {
            player.currentTime(duration_1.convertStringToSeconds(elapsedTime));
        }
        player.on(constants_1.PLAYER_EVENTS.ENDED, function () {
            _this.endScreen(_this.containerElement);
        });
    };
    /**
     * Get latest instance of Live Player
     */
    LivePlayer.prototype.getPlayer = function () {
        this.player = video_js_1.default.getPlayer(this.playerElement);
        return this;
    };
    /**
     * Starts Video
     */
    LivePlayer.prototype.start = function () {
        if (!lodash_1.isEmpty(this.player)) {
            this.player.muted(false);
            this.player.play();
        }
    };
    /**
     * Stops Video
     */
    LivePlayer.prototype.stop = function () {
        if (!lodash_1.isEmpty(this.player)) {
            this.player.pause();
        }
    };
    /**
     * Enters fullscreen for player
     */
    LivePlayer.prototype.openFullscreen = function () {
        if (!lodash_1.isEmpty(this.player)) {
            this.player.requestFullscreen();
        }
    };
    /**
     * Exits fullscreen for player
     */
    LivePlayer.prototype.closeFullscreen = function () {
        if (!lodash_1.isEmpty(this.player)) {
            this.player.exitFullscreen();
        }
    };
    /**
     * Destroys Live Player
     */
    LivePlayer.prototype.dispose = function () {
        if (!lodash_1.isEmpty(this.player)) {
            this.player.dispose();
            this.playerElement.innerHTML = null;
        }
    };
    /**
     * Changes volume on player
     */
    LivePlayer.prototype.volume = function (volume) {
        if (!lodash_1.isEmpty(this.player)) {
            var updateVolume = lodash_1.toNumber(volume);
            if (updateVolume >= 0 && updateVolume <= 1) {
                this.player.volume(updateVolume);
            }
        }
    };
    /**
     * Mount EndScreen
     */
    LivePlayer.prototype.endScreen = function (element) {
        if (!lodash_1.isUndefined(element)) {
            this.state.episode = null;
            this.containerElement = element;
            this.containerElement.innerHTML = streamEndedElement_1.default();
        }
    };
    /**
     * Mount empty placeholder
     */
    LivePlayer.prototype.emptyScreen = function (element) {
        if (!lodash_1.isUndefined(element)) {
            this.containerElement = element;
            this.containerElement.innerHTML = streamEmptyStateElement_1.default();
        }
    };
    /**
     * Retrieves the first initial state
     */
    LivePlayer.prototype.initialState = function (options) {
        return {
            loading: false,
            episode: null,
            broadcast: null,
            featuredProducts: null,
            loadingShopifyConfig: false,
        };
    };
    return LivePlayer;
}());
exports.default = LivePlayer;
