"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var date_fns_1 = require("date-fns");
var FeaturedProductsContainer_1 = require("../../../Player/components/FeaturedProductsContainer");
/**
 * Mounts VOD Player element template
 */
function default_1(vodData, _a) {
    var _b, _c;
    var hideProducts = _a.hideProducts, hideDuration = _a.hideDuration;
    var _d = vodData || {}, title = _d.title, asset = _d.asset, previewAsset = _d.preview_asset, duration = _d.duration;
    var assetImage = (_b = asset.image) === null || _b === void 0 ? void 0 : _b.full_size;
    var previewImage = (_c = previewAsset === null || previewAsset === void 0 ? void 0 : previewAsset.image) === null || _c === void 0 ? void 0 : _c.full_size;
    var thumbnailImage = lodash_1.isEmpty(previewImage) ? assetImage : previewImage;
    function renderDuration() {
        return hideDuration
            ? ''
            : "<span class=\"duration\">\n          " + date_fns_1.formatDuration(date_fns_1.intervalToDuration({ start: 0, end: duration * 1000 })) + "\n        </span>";
    }
    return "\n    <div class=\"video-container\">\n      <div class=\"vod-player-container\" data-vjs-player=\"true\">\n          <video id=\"vod-player-video-element\" class=\"video-js vjs-16-9 vjs-big-play-centered\"\n          playsInline=\"true\"></video>\n          <div class=\"vod-overlay-content-container\"></div>\n          " + (hideProducts ? '' : FeaturedProductsContainer_1.default()) + "\n      </div>\n      <div class=\"video-cover\">\n        " + (!lodash_1.isEmpty(thumbnailImage) &&
        "<img src=" + thumbnailImage + " alt=\"" + title + "\" />") + "\n        <div class=\"cover-container\">\n          " + renderDuration() + "\n        </div>\n        <button type=\"button\" class=\"play-button\">\n          <svg width=\"17\" height=\"18\" viewBox=\"0 0 17 18\" xmlns=\"http://www.w3.org/2000/svg\">\n            <path d=\"M1.526 1.517c-.293.3-.47.695-.499 1.114l.077 12.393c.169.502.532.862 1.007 1.02.346.115.765.126 1.215-.046l10.61-5.305a2.34 2.34 0 0 0 1.046-1.047c.28-.56.316-1.176.126-1.747-.176-.53-.553-1.027-1.143-1.377L2.892 1.072a1.58 1.58 0 0 0-1.365.445h-.001z\" stroke=\"#22174A\" stroke-width=\"1.8\" fill=\"none\" fill-rule=\"evenodd\"/>\n          </svg>\n        </button>\n      </div>\n    </div>\n    ";
}
exports.default = default_1;
