"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var video_js_1 = require("video.js");
var lodash_1 = require("lodash");
require("../styles/index.css");
var constants_1 = require("./constants");
var VodPlayerElement_1 = require("./components/VodPlayerElement");
var constants_2 = require("../Player/constants");
var CloseButton_1 = require("./components/CloseButton");
var constants_3 = require("../Player/components/FeaturedProductsContainer/constants");
var Loader_1 = require("./components/Loader");
var product_1 = require("../Player/components/FeaturedProductsContainer/product");
var duration_1 = require("../../utils/duration");
var VodPlayer = /** @class */ (function () {
    function VodPlayer(_a) {
        var containerElement = _a.containerElement, videoData = _a.videoData, _b = _a.options, options = _b === void 0 ? {} : _b, liveInstance = _a.liveInstance;
        this.featuredProducts = [];
        this.visibleProducts = [];
        this.live = liveInstance;
        this.containerElement = containerElement;
        this.videoData = videoData;
        this.options = options;
        this.init();
    }
    VodPlayer.prototype.init = function () {
        var _a = this.options, hideProducts = _a.hideProducts, hideDuration = _a.hideDuration;
        this.containerElement.innerHTML = VodPlayerElement_1.default(this.videoData, {
            hideProducts: hideProducts,
            hideDuration: hideDuration,
        });
        var videoElement = this.containerElement.querySelector(constants_2.VIDEOJS_SELECTOR);
        this.player = video_js_1.default(videoElement, constants_1.VOD_PLAYER_OPTIONS);
        this.player.src({
            src: this.videoData.asset.asset_url,
        });
        this.renderCloseButton();
        this.containerElement
            .querySelector('.play-button')
            .addEventListener('click', this.onPlay.bind(this));
        this.renderFeaturedProducts();
    };
    VodPlayer.prototype.renderFeaturedProducts = function () {
        var _this = this;
        if (!this.options.hideProducts) {
            this.live.elements
                .getVideoFeaturedProductsMeta({
                videoId: this.videoData.id,
                params: {
                    per_page: 1000,
                },
            })
                .then(function (productsMetaData) {
                _this.player.on('timeupdate', function () {
                    var currentTime = _this.player.currentTime();
                    var updatedVisibleProductIdList = lodash_1.map(lodash_1.filter(productsMetaData, function (productData) {
                        var highlightTimings = productData.highlight_timings;
                        var isTimingPresent = !lodash_1.isEmpty(highlightTimings) && !lodash_1.isEmpty(highlightTimings[0]);
                        var startTime = isTimingPresent
                            ? duration_1.convertStringToSeconds(highlightTimings[0].start_time)
                            : 0;
                        var endTime = isTimingPresent
                            ? duration_1.convertStringToSeconds(highlightTimings[0].end_time)
                            : _this.player.duration();
                        return (currentTime >= startTime - constants_1.PRE_FETCH_VALUE &&
                            currentTime <= endTime);
                    }), 'product_id');
                    _this.updateVisibleProducts(updatedVisibleProductIdList);
                });
            });
        }
    };
    VodPlayer.prototype.updateVisibleProducts = function (updatedVisibleProductList) {
        var _this = this;
        var featuredProductsContainerElement = document.getElementById(constants_3.FEATURED_PRODUCTS_CONTAINER_ELEMENT_ID);
        if (lodash_1.isEmpty(updatedVisibleProductList) &&
            !featuredProductsContainerElement.hidden) {
            featuredProductsContainerElement.hidden = true;
        }
        else if (!lodash_1.isEmpty(updatedVisibleProductList) &&
            featuredProductsContainerElement.hidden) {
            featuredProductsContainerElement.hidden = false;
        }
        var hiddenProducts = lodash_1.differenceBy(this.visibleProducts, updatedVisibleProductList);
        var newFeaturedProducts = lodash_1.differenceBy(updatedVisibleProductList, this.visibleProducts);
        if (!lodash_1.isEmpty(hiddenProducts)) {
            hiddenProducts.forEach(function (hiddenProduct) {
                document.getElementById("product-" + hiddenProduct).remove();
            });
        }
        if (!lodash_1.isEmpty(newFeaturedProducts)) {
            newFeaturedProducts.forEach(function (productId) {
                var product = lodash_1.find(_this.featuredProducts, { id: productId });
                if (lodash_1.isEmpty(product)) {
                    _this.renderProductLoader("loader-" + productId);
                    _this.live.elements
                        .getProductDetails(productId)
                        .then(function (fetchedProduct) {
                        _this.featuredProducts = __spreadArrays(_this.featuredProducts, [
                            fetchedProduct,
                        ]);
                        document.getElementById("loader-" + productId).remove();
                        _this.renderProduct(fetchedProduct);
                    });
                }
                else {
                    _this.renderProduct(product);
                }
            });
        }
        this.visibleProducts = __spreadArrays(lodash_1.pull.apply(void 0, __spreadArrays([this.visibleProducts], hiddenProducts)), newFeaturedProducts);
    };
    VodPlayer.prototype.renderProduct = function (product) {
        document
            .getElementById(constants_3.PRODUCT_LIST_ELEMENT_ID)
            .insertAdjacentHTML('afterbegin', product_1.default({ product: product, useUrl: true }));
    };
    VodPlayer.prototype.renderProductLoader = function (loaderId) {
        document
            .getElementById(constants_3.PRODUCT_LIST_ELEMENT_ID)
            .insertAdjacentHTML('afterbegin', Loader_1.default(loaderId));
    };
    VodPlayer.prototype.renderCloseButton = function () {
        if (this.options.closable) {
            this.containerElement
                .querySelector('.vod-overlay-content-container')
                .insertAdjacentHTML('afterbegin', CloseButton_1.default());
            this.containerElement
                .querySelector('.close-button')
                .addEventListener('click', this.dispose.bind(this));
        }
    };
    VodPlayer.prototype.onPlay = function () {
        this.containerElement.querySelector('.video-cover').hidden = true;
        this.player.play();
    };
    VodPlayer.prototype.dispose = function () {
        this.containerElement.innerHTML = '';
        this.player.dispose();
    };
    return VodPlayer;
}());
exports.default = VodPlayer;
