/**
 * error element
 */
export default function (error) {
  return `
    <pre class='error-text'>
      ${error}
    </pre>
  `;
}
